import Articles from '@/components/Articles'
import Estates from '@/components/Estates'
import Layout from '@/components/Layout'
import React, { Component } from 'react'

export default class fastigheter extends Component {
    render() {
        return (
        <Layout active={2} title="Fastigheter">
            <Estates full={true}/>
            <Articles bottom={false} amount={3}/>
        </Layout>
        )
    }
}
